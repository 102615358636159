@import '../../app/assets/styles/mixins';

.root {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px auto;
  padding: 0 32px;
  max-width: 1440px;

  @media (max-width: 768px) {
    display: none;
  }
}

.container {
  &__left {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
      height: 50px;
    }
  }
}

.header {
  &__sell {
    color: #ebebeb;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 16px;
    transition: all 0.3s ease;
    &:hover {
      transition: all 0.3s ease;
      color: rgba(235, 235, 235, 0.7);
    }
  }
  &__button {
    height: 50px;

    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgba(166, 174, 203, 0.1);
    transition: all 0.3s ease;
    gap: 10px;

    p {
      color: var(--Content-Primary, #ebebeb);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    &:hover {
      transition: all 0.3s ease;
      background: rgba(166, 174, 203, 0.2);
    }

    &__text {
      padding: 0 16px;
    }

    &__icon {
      width: 50px;
    }

    &--profile {
      display: flex;
      align-items: center;
      max-width: 130px;
      width: fit-content;
      height: 50px;
      flex-shrink: 0;
      border-radius: 16px;
      background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
      backdrop-filter: blur(25px);
      padding: 5px;
      gap: 10px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 11px;
        background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
        width: 40px;
        height: 40px;
      }
      p {
        color: var(--Content-Primary, #ebebeb);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
