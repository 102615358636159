@import '../../app/assets/styles/mixins';
.root {
  display: none;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  background: #0c0c0c;
  min-width: 300px;
  width: 300px;
  padding: 50px 40px;
  align-self: flex-start;
  height: 100%;

  @media (max-width: 768px) {
    display: flex;
    height: calc(100dvh - 55px);
  }

  &__open {
    @media (max-width: 768px) {
      display: flex;
      position: fixed;
      top: 55px;
      width: 100%;
      left: 0;
      padding: 0 20px;
      overflow: scroll;

      @include scrollbar(0px, #595778, var(--light), 0px, 0px);
    }
  }
  &__closed {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.navbar {
  &__logo {
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 50px;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    padding: 10px 10px 10px 0;
    align-items: center;
  }
  &__alert {
    margin: 10px 0 auto;
  }
}

.line {
  background: rgba(255, 255, 255, 0.05);
  height: 1px;
  width: 100%;
}

.powered {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
  p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
  }
  a {
    color: rgb(4, 123, 252);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
  }
}

.link {
  display: flex;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  padding: 10px 0;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 10px 0;
  align-items: center;
  &__red {
    color: #db4437;
  }
  &__underlined {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
}

.line {
  margin: 10px 0;
}
.arrow {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 24px;
    height: 24px;
  }
  &-down {
    &__new {
      position: absolute;
      top: -24px;
      transition: all 0.3s ease;
      svg {
        transform: rotate(90deg);
      }
      &--show {
        transition: all 0.3s ease;
        top: 0;
      }
    }
    &__old {
      position: absolute;
      top: 0px;
      transition: all 0.3s ease;
      svg {
        transform: rotate(90deg);
      }
      &--hide {
        transition: all 0.3s ease;
        top: 24px;
      }
    }
  }

  &-right {
    &__new {
      position: absolute;
      left: -24px;
      transition: all 0.3s ease;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &--show {
        transition: all 0.3s ease;
        left: 0;
      }
    }
    &__old {
      position: absolute;
      left: 0px;
      transition: all 0.3s ease;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &--hide {
        transition: all 0.3s ease;
        left: 24px;
      }
    }
  }

  &-top-right {
    &__new {
      position: absolute;
      height: 24px;
      width: 24px;
      transition: all 0.3s ease;
      top: 24px;
      right: 24px;
      &--show {
        height: 24px;
        width: 24px;
        top: 0;
        left: 0;
        animation: moveDiagonalShow 0.3s ease forwards;
      }
    }

    &__old {
      position: absolute;
      height: 24px;
      width: 24px;
      transition: all 0.3s ease;
      top: 0px;
      right: 0px;

      &--hide {
        height: 24px;
        width: 24px;
        top: -24px;
        right: -24px;
        animation: moveDiagonalHide 0.3s ease forwards;
      }
    }
  }
}

.signin,
.organizer {
  height: 44px;
  margin-top: 0 !important;
  width: 100%;
  &__icon {
    margin-right: 6px;
  }
}

.links {
  &__title {
    color: var(--Content-White, #fff);

    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    opacity: 0.5;
    text-transform: uppercase;
  }
  &__elem {
    color: var(--Content-Secondary, #ccc);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 0;
  }
  &__container {
    padding: 20px 0;

    border-top: 1px solid rgba(166, 174, 203, 0.12);
    &:first-child {
      margin-top: 24px;
      border-bottom: 1px solid rgba(166, 174, 203, 0.12);
    }
    &:last-child {
      border-bottom: 1px solid rgba(166, 174, 203, 0.12);
    }
  }

  &__container:first-child {
    height: 100%;
  }
}

.social {
  display: flex;
  align-items: center;
  gap: 12px;
  &__letter {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(166, 174, 203, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(166, 174, 203, 0.12);
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &__link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
}

.text {
  &__bold {
    color: #ebebeb;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 68px;
  }
  &__default {
    display: block;
    margin-top: 24px;
    max-width: 885px;
    width: 100%;
    color: #a3a3a3;
    text-align: left;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    p {
      display: inline-block;
    }
  }
}

.footer {
  padding: 24px 0;

  a {
    color: #ebebeb;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    transition: all 0.3s ease;
    &:hover {
      color: #fff;
      transition: all 0.3s ease;
    }
  }
}

@keyframes moveDiagonalShow {
  0% {
    transform: translate(-100%, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveDiagonalHide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, -100%);
  }
}
