@import '../../../../app/assets/styles/mixins';
.header {
  &__container {
    gap: 26px;
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    margin: auto;

    padding: 16px 40px 0;
  }

  @media (max-width: 1024px) {
    &__container {
      padding: 16px 20px 0;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: -6px;

    p {
      color: var(--Content-Primary, #ebebeb);

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(166, 174, 203, 0.12);
  }

  @media (max-width: 768px) {
    &__info {
      display: none;
    }
  }

  &__amount {
    p {
      color: var(--Content-Tertiary, #a3a3a3);

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    b {
      color: var(--Content-White, #fff);

      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
    }
  }
  &__offerta {
    a {
      color: var(--Content-Tertiary, #a3a3a3);
      text-align: right;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.7;
        transition: all 0.3s ease;
      }
    }
  }
}
