@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 100px;
}

.title {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    display: flex;
    align-items: center;
    margin-top: 4px;
    @media (max-width: 768px) {
      width: 8px;
      height: 12px;
    }
  }
  @media (max-width: 768px) {
    &__arrow {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
  }
}

.events {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px 24px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 32px;
  }
}

.tubs {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-left: auto;
  @media (max-width: 768px) {
    //margin-right: auto;
    margin-left: 0;
  }
}
.tub {
  color: #a3a3a3;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  position: relative;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
  &::before {
    content: '';
    max-width: 0;
  }

  &__active {
    color: #fff;
    &::before {
      content: '';
      bottom: -8px;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      height: 2px;
      width: 100%;
      max-width: 40px;
      align-self: stretch;
      border-radius: 16px;
      background: #fff;
    }
  }
  &:hover {
    color: #fff;
    &::before {
      content: '';
      bottom: -8px;
      transition: all 0.4s ease;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      height: 2px;
      width: 100%;
      max-width: 40px;
      align-self: stretch;
      border-radius: 16px;
      background: #fff;
    }
  }
}
