@import "../../app/assets/styles/colors";
@import "../../app/assets/styles/sizes";

.root {
  display: flow;
  gap: 12px;
  align-items: center;
}

.link {
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px $border-2;
  text-decoration: none;

  color: $content-primary;
  font-weight: 500;
  font-size: 14px;
  padding-right: 5px;
  display: inline-flex;

  img {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
  }
}
