@import '../../app/assets/styles/mixins';
.root {
  display: grid;
  margin-top: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  border-radius: 16px;
  background: rgba(166, 174, 203, 0.1);
  height: 64px;
  @media (max-width: 768px) {
    width: fit-content;
  }

  @media (max-width: 768px) {
    height: 44px;
  }

  p {
    border-right: 1px solid rgba(166, 174, 203, 0.1);
    border-left: 1px solid rgba(166, 174, 203, 0.1);
    display: flex;
    justify-content: center;
    color: #ebebeb;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    align-items: center;
    @media (max-width: 768px) {
      width: 46px;
      font-size: 14px;
      line-height: 18px;
    }
  }
  button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    @media (max-width: 768px) {
      width: 46px;
    }
  }
}
