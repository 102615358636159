@import "../../app/assets/styles/colors";
@import "../../app/assets/styles/sizes";

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 24px;
  align-items: center;
  padding: 20px 32px;

  background: $background-surface-light;
  border: 1px solid $border-4;

  &__info {
    display: flex;
    flex-direction: row;
    gap: 24px;
    border-radius: 24px;
    align-items: center;

    &__icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      user-select: none;
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__title {
        color: $content-white;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      &__message {
        color: $content-tertiary;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
      }

      .warning &__message,
      .paused &__message,
      .info &__message,
      .verified &__message {
        color: $content-white;
      }
    }
  }
}



.warning {
  border: 1px solid rgba(166, 174, 203, 0.04);
  background: $background-negative;
}

.paused {
  background: var(--Content-Warning, #E08A25);
}

.info, .verified {
  background: $background-blue;

}

@media (max-width: $tablet) {
  .root {
    padding: 22px;
  }
}

@media (max-width: $mobile) {
  .root {
    flex-direction: column;
    align-items: start;
    gap: 16px;

    &__info {
      flex-direction: column;
      align-items: start;
      gap: 16px;

      &__details {
        &__title {
          font-size: 14px;
          line-height: 20px;
        }

        &__message {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}
