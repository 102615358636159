@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  &__description {
    display: none;
    color: #d6d6d6;
    margin-top: 40px;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    max-width: 780px;
    width: 100%;

    white-space: break-spaces;

    @media (max-width: 768px) {
      display: flow;
      font-size: 14px;
      line-height: 20px;

      margin-bottom: 100px;
    }
  }
}

.location {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  border-radius: 24px;
  border: 1px solid rgba(166, 174, 203, 0.24);

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  &__column {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 20px;
    @media (max-width: 768px) {
      padding: 16px 0px;
    }
    img {
      @media (max-width: 768px) {
        width: 24px;
        height: 24px;
      }
    }
    &:last-child {
      border-top: 1px solid rgba(166, 174, 203, 0.24);
    }
  }
  &__texts {
    p {
      color: #d6d6d6;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
    span,
    address {
      color: #ebebeb;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      margin-top: 4px;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.gmt {
  color: #d6d6d6;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 14px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(166, 174, 203, 0.12);
  }
}

.description {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  gap: 16px;

  &__item {
    display: flex;
    align-items: center;
    gap: 6px;
    img {
      margin-right: 10px;
      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
    p {
      color: #a3a3a3;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
    b, span
    {
      color: #d6d6d6;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
