@import '../../app/assets/styles/mixins';
.root {
  display: none;
  align-items: center;
  position: sticky;
  background: #0c0c0c;
  justify-content: flex-start;
  top: 0;
  z-index: 99;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px 20px;
    height: 72px !important;
    height: auto;
  }
  &__open {
    position: fixed;
  }
}

.header {
  &__burger {
    display: none;
    align-items: center;
    justify-content: center;
    width: 22px;
    @media (max-width: 768px) {
      display: flex;
    }
  }
  &__searchbar {
    display: none;
    align-items: center;
    margin-left: auto;
    @media (max-width: 768px) {
      display: flex;
    }
    &--open {
      transition: all 0.3s ease;
      width: 258px;
      input {
        height: 39px !important;
      }
    }
    &--closed {
      transition: all 0.3s ease;
      width: 0;
      input {
        padding: 0 !important;
        border: none !important;
      }
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    @media (min-width: 768px) {
      width: 175px !important;
      height: 25px;
      img {
        width: 100%;
        height: 25px;
      }
    }
    &--open {
      transition: all 0.3s ease;
      width: 106px;
    }
    &--closed {
      transition: all 0.3s ease;
      //overflow: hidden;
      width: 0;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
}

.userblock {
  color: var(--Content-Primary, #ebebeb);

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  border-radius: 12px;
  background: rgba(166, 174, 203, 0.1);

  display: flex;
  width: 40px;
  padding: 6px 13px;
  justify-content: center;
  align-items: center;
}

.header__user {
  margin-left: auto;
}
