$brand-primary: #AA37D2;
$brand-1: #AE52FF;

$content-white: #FFFFFF;
$content-primary: #EAEAEA;
$content-secondary: #CCCCCC;
$content-tertiary: #A3A3A3;
$content-negative: #ED603E;
$content-primary-on-light: #0C0A0C;
$content-positive: #92C53A;
$content-warning: #E08A25;
$content-blue: #25A8FF;

$background-primary: #0C0B0C;
$background-surface: rgba(166, 174, 203, 10%);
$background-surface-hover: rgba(166, 174, 203, 20%);
$background-surface-active: rgba(166, 174, 203, 30%);
$background-surface-light: rgba(166, 174, 203, 4%);
$background-surface-solid: #151515;
$background-surface-disabled: rgba(166, 174, 203, 8%);
$background-white: #FFFFFF;
$background-light: #FAFAFA;
$background-overlay: rgba(12, 11, 12, 90%);
$background-red: #F21B68;
$background-popup-solid: #26272D;
$background-negative: #ED603E;
$background-blue: #25A8FF;

$border-1: rgba(166, 174, 203, 24%);
$border-2: rgba(166, 174, 203, 12%);
$border-3: rgba(166, 174, 203, 8%);
$border-4: rgba(166, 174, 203, 4%);
$border-negative: #ED603E;
$border-dark: #0C0B0C;
$border-white: #FFFFFF;
