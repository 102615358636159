@import '../../app/assets/styles/mixins';
.root {
  display: flex;
}
.main {
  // padding-left: 100px;
  // padding-right: 20px;
  width: 100%;
  @media (max-width: 1400px) {
    //padding-left: 20px;
  }
  @media (max-width: 768px) {
    padding-top: 0px;
    padding-left: 0;
  }
  &__woheader {
    @media (max-width: 768px) {
      padding: 0;
    }
  }
}
