@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  width: 380px;
  height: 50px;
  border-radius: 100px;

  background: rgba(166, 174, 203, 0.1);
  gap: 16px;
  backdrop-filter: blur(25px);
  display: flex;
  align-items: center;
  padding: 0 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0);
  &:hover {
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  input {
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: transparent;
    width: 100%;
    outline: none;
  }
}
