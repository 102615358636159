@import '../../../../app/assets/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__notion {
    display: block;

    margin-top: 24px;
    color: var(--Content-Tertiary, #a3a3a3);

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    a {
      display: inline-flex;
      white-space: nowrap;
      color: var(--Content-Primary, #ebebeb);

      margin-left: 5px;

      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.7;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.columns {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(166, 174, 203, 0.12);
}

.column {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  b {
    color: var(--Content-Primary, #ebebeb);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  p {
    color: var(--Content-Primary, #ebebeb);

    text-align: right;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  span {
    color: var(--Content-Tertiary, #a3a3a3);

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 24px;
  img {
    object-fit: cover;
    border-radius: 12px;
    border: 1px solid var(--Border-2, rgba(166, 174, 203, 0.12));
  }
}

@media (max-width: 768px) {
  .left {
    align-items: flex-start;
    img {
      width: 70px;
      height: 48px;
    }
  }
}

.confirmation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;



  margin-top: 40px;

  &__promocode {
    display: flex;
    align-items: center;

    gap: 16px;
    &__btn {
      display: flex;
      height: 54px;
      min-height: 48px;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
      color: var(--Content-White, #fff);

      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      cursor: not-allowed;
    }

    &__input {
      display: flex;
      align-items: center;
      position: relative;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
      padding: 15px 16px 15px 48px;
      width: 100%;
      input {
        color: var(--Content-Primary, #ebebeb);

        font-size: 15px;
        font-weight: 400;
        width: 100%;
        background: transparent;
        outline: none;
      }
      img {
        position: absolute;
        left: 15px;
        top: 15px;
      }
    }
  }

  > button {
    width: fit-content;
    padding: 12px 40px;
  }
}

@media (max-width: 1500px) {
  .confirmation {
    flex-direction: column;
    gap: 24px;

    > button {
      width: 100%;
    }
  }
}
