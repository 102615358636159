@import '../../../../app/assets/styles/mixins';

.root {
  display: flex;
  padding: 30px 40px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 32px;
  background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
  width: 100%;
  &__title {
    color: var(--Content-White, #fff);

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  &__buttons {
    display: flex;
    width: 100%;
    gap: 12px;

    > * {
      width: 100%;
    }
  }
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &__image {
    margin-bottom: 24px;
  }

  &__title {
    color: var(--Content-Primary, #ebebeb);
    text-align: center;

    font-size: 17px;
    font-weight: 600;
  }

  &__loader {
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 8px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: center;
      width: 21px;
      height: 21px;
      animation: spin 1s infinite linear;
    }
    p {
      color: var(--Content-Secondary, #ccc);
      text-align: center;
      width: 100%;

      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    a {
      color: #fff;
      display: block;
    }
  }

  &__timer {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      color: var(--Content-White, #fff);

      font-size: 48px;
      font-weight: 700;
    }
  }

  &__progress {
    width: 220px;
    max-width: 100%;
    height: 8px;
    background: rgba(166, 174, 203, 0.1);
    margin-top: 12px;
    border-radius: 8px;
    margin-bottom: 24px;
    span {
      display: flex;
      width: 40px;
      height: 8px;
      background: linear-gradient(135deg, #4790fe, #aa37d2);
      border-radius: 8px;
    }
  }
}

@media (max-width: 768px) {
  .root {
    padding: 30px 20px 20px;

    &__title {
      display: none;
    }
  }

  .block {
    &__image {
      width: 100px;
      height: 100px;
      margin-bottom: 24px;
    }
  }
}

.link {
  margin-top: 48px;
  display: flex;
  align-items: center;
  height: 76px;
  width: 100%;
  border-radius: 24px;
  background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
  padding-left: 16px;
  padding-right: 32px;

  &__image {
    margin-top: auto;
    position: relative;
    z-index: 10;
  }

  &__column {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    p {
      color: var(--Content-Primary, #ebebeb);

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    span {
      color: var(--Content-Tertiary, #a3a3a3);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__button {
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .link {
    height: 70px;
    padding-left: 6px;

    display: none;

    &__column {
      p {
        font-size: 14px;
        line-height: 20px;
      }
      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  margin-top: 30px;
}
