@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 20px 20px;
    max-width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 998px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  &__title {
    color: #fff;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__badge {
    display: flex;
    border-radius: 24px;
    background: #ebebeb;
    height: 30px;
    align-items: center;
    box-shadow: 0px 2px 16px 0px rgba(12, 11, 12, 0.04);
    backdrop-filter: blur(2px);
    p {
      color: #0c0a0c;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0 15px 0 8px;
      white-space: nowrap;
    }

    span {
      background: linear-gradient(to bottom, #ff4e2b, #ff802b);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      border-radius: 35px 25px 25px 35px;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.alert {
  margin-top: 32px;
}

.price {
  margin-top: 32px;
  &__block {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  p {
    color: #a3a3a3;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    @media (max-width: 768px) {
      font-size: 11px;
      line-height: 14px;
    }
  }
  b {
    color: #fff;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.description {
  color: #d6d6d6;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 36px;
  @media (max-width: 768px) {
    display: none;
  }
}

@media (min-width: 768px) {
  .counter { display: grid !important; }
  .counter__mob { display: none !important; }
}
@media (max-width: 768px) {
  .counter { display: none !important; }
  .counter__mob { display: grid !important; }
}

.buy {
  &__button {
    margin-top: 32px;
    width: 100%;
  }

  @media (max-width: 768px) {
    &__container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 0 20px;
    }

    &__button {
      margin: 0 auto 32px;
    }
  }
}


