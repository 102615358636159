@import "../../app/assets/styles/colors";
@import "../../app/assets/styles/sizes";

.root {
  display: flex;
  height: 54px;
  min-height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: $brand-primary;

  color: $content-white;

  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  transition: all 0.3s ease;

  white-space: nowrap;

  svg {
    margin-right: 8px;
  }

  @media (max-width: $tablet) {
    height: 44px;
    font-size: 14px;
    line-height: 20px;
  }

  &:disabled {
    background: $background-surface;
    color: $content-tertiary;
    &:hover {
      cursor: not-allowed;
      transition: all 0.3s ease;
      box-shadow: none;
    }
  }

  &:hover {
    transition: all 0.3s ease;
    box-shadow: 0px 0px 40px 0px rgba(170, 55, 210, 0.6);
  }

  &.small {
    height: 44px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  &.dark,
  &.secondary{
    background: $background-surface;
    &:hover {
      background: $background-surface-hover;
      box-shadow: none;
      transition: all 0.3s ease;
    }
  }
}


