@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.26px;

  color: #CCCCCC;

  p {
    margin-top: 2px;
  }

  img {
    height: 18px;
    width: 18px;
    display: flex;

    @media (max-width: 768px) {
      scale: 0.7;
    }
  }

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 13px;
    gap: 2px;
  }
}

.big {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.32px;

  img {
    height: 24px;
    width: 24px;
  }
}

.verified {
  span {
    background-color: #222;
    color: #333;
  }
}

.unverified {
  span {
    background-color: #F49629;
    color: #d6d6d6;
  }
}
