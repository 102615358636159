@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.image {
  margin-top: 100px;
  border-radius: 50%;
  @media (max-width: 768px) {
    margin-top: 44px;
    width: 116px;
    height: 116px;
  }
}

.title {
  color: #fff;
  margin-top: 24px;

  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.organization {
  &__verified {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 8px;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.26px;
    span {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #219dff;
      color: #d6d6d6;
    }
  }
}

.statistic {
  display: flex;
  align-items: center;
  margin-top: 48px;
  gap: 64px;
  @media (max-width: 768px) {
    gap: 22px;
  }

  @media (max-width: 414px) {
    gap: 16px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    b {
      color: #fff;

      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
    }

    p {
      color: #a3a3a3;
      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
