.grid {
  display: flex;
  gap: 80px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 40px 40px 100px;
}

@media (max-width: 1024px) {
  .grid {
    flex-direction: column-reverse;
    gap: 40px;
    padding: 40px 20px 100px;
  }
}

.popup {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    margin-top: 24px;
  }
  &__paragraph {
    color: var(--Content-Secondary, #ccc);

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 24px;
  }
}
