@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  flex-direction: column;
  transition: opacity;
  &:hover {

  }
}

.header {
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    img {
      width: 100%;
    }
  }
  &::before {
    content: '';
    transition: all 0.3s ease;
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 21px;
  }
  &__image {
    border-radius: 24px;
    height: auto;
    object-fit: cover;
    aspect-ratio: 5/3;
  }
  &__data {
    position: absolute;
    display: flex;
    top: 16px;
    left: 16px;
    gap: 17px;
    span {
      border-radius: 24px;
      background: #fff;
      display: flex;
      width: fit-content;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      box-shadow: 0px 2px 16px 0px rgba(12, 11, 12, 0.04);
      backdrop-filter: blur(2px);
      color: #0c0a0c;
      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media (max-width: 414px) {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}

.footer {
  display: flex;
  gap: 20px;
  margin-top: 24px;
  padding-left: 16px;
  margin-right: auto;
  @media (max-width: 414px) {
    margin-top: 14px;
  }
  &__date {
    display: flex;
    flex-direction: column;
    @media (max-width: 414px) {
      gap: 4px;
    }
    b {
      color: #ebebeb;

      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      @media (max-width: 414px) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    span {
      color: #a3a3a3;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      @media (max-width: 414px) {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
  &__concert {
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width: 414px) {
      gap: 4px;
    }
    b {
      color: #ebebeb;

      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      @media (max-width: 414px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__organization {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    p {
      color: #d6d6d6;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      @media (max-width: 414px) {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
}
