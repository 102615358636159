@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  position: relative;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    height: 450px;
  }
  //&::before {
  //  content: '';
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  background: linear-gradient(
  //    180deg,
  //    rgba(12, 11, 12, 0.12) 0%,
  //    rgba(12, 11, 12, 0.72) 52.7%,
  //    rgba(12, 11, 12, 0.94) 82.85%,
  //    #0c0b0c 100%
  //  );
  //  z-index: 20;
  //}

  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    object-fit: cover;
    opacity: 0.3;
    @media (max-width: 768px) {
      height: 100%;
    }
  }

  .container {
    z-index: 40;
  }
}
